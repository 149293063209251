.app {
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden;
}

.parallax {
  /* background:url("./Images/background.gif") repeat fixed 100%; */
  background:url("./Images/bg2.jpg") repeat fixed 100%;
  /* background:url("https://i.ibb.co/3sywb0G/51.jpg") repeat fixed 100%; */
  /* background: url("https://i.ibb.co/kHxwKFP/ezgif-com-gif-maker.gif");
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center center; */
}
