@tailwind base;
@tailwind components;
@tailwind utilities;



*{
  margin : 0;
  padding : 0;
}


 /* ::-webkit-scrollbar {
    width: 16px;
}
 
 ::-webkit-scrollbar-track {
    background-color: #122E46;
}
 
 ::-webkit-scrollbar-thumb {
    background-color: #d4aa70;
    border-radius: 100px;
} */