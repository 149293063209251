@import url("https://fonts.googleapis.com/css2?family=Zen+Loop&display=swap");

@font-face {
  font-family: "Azonix Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Azonix Regular"), url("./Fonts/Azonix.woff") format("woff");
}
.header__text {
  font-family: "Azonix Regular";

  
}
.pro {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
/* 
.blooog{
    padding-left:56px;
    padding-right:80px;
} */
.blogg {
  height: 100vh;
  position: absolute;
}

hDiv {
  width: 100%;
  height: 20px;
  border-bottom: 1px solid black;
  text-align: center;
}
hDivSpan {
  font-size: 40px;
  background-color: #f3f5f6;
  padding: 0 10px;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.para {
  /* font-family: 'monospace';
  font-family: "Zen Loop", cursive; */
  /* color: yellow; */
  /* font-size: 1rem; */
  padding: 20px;
  /* font-weight: bold; */
}
.arrow_down_div{
  /* border-radius: 5px; */
}

.arrow_down{
  color:#F59E0B;
  font-size : 30px !important;
  border: 1px solid #F59E0B;
  border-radius: 999px;
  margin-top: 30px;
  /* padding-top: 55px; */
  transform: scale(1.8);
}



.link{
  color:#F59E0B;
}
.up_down_ani{
  animation: MoveUpDown 2.5s linear infinite;

}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(40px);
  }
}